<template>
  <div class="fv-plugins-message-container">
    <div :data-field="field" data-validator="notEmpty" class="fv-help-block">
      {{ errors[0] }}
    </div>
  </div>
</template>

<script>
export default {
  name: "ErrorValidateMessage",
  props: {
    errors: Array,
    field: {
      type: String,
      default: ''
    }
  }
}
</script>
