<template>
  <div class="row">
    <div class="col-xl-12">
      <div id="panel-1" class="panel">
        <div class="panel-container show">
          <div class="panel-content">
            <div>
              <h4 class="title">Thông tin khách hàng</h4>
              <div class="row form">
                <div class="col-md-3 form-group">
                  <label>Họ tên</label>
                  <el-input type="text" :value="user.name" disabled />
                </div>
                <div class="col-md-3 form-group">
                  <label>Số điện thoại</label>
                  <el-input type="text" :value="user.phone" disabled />
                </div>
              </div>
            </div>
            <el-divider></el-divider>
            <div>
              <h4 class="title">Thông tin người làm trả góp</h4>
              <ValidationObserver ref="form" v-slot="{ handleSubmit }">
                <form @submit.prevent="handleSubmit(summit)" autocomplete="off">
                  <div class="row">
                    <div class="col-md-3 form-group">
                      <label for="name">Họ tên <span class="text-danger">*</span></label>
                      <ValidationProvider vid="name" name="Họ và tên" rules="required|max:50" v-slot="{ errors, classes }">
                        <el-input id="name" type="text" :class="classes" v-model="form.name" placeholder="Nhập họ tên người làm trả góp"/>
                        <error-validate-message field="phone" :errors="errors"></error-validate-message>
                      </ValidationProvider>
                    </div>
                    <div class="col-md-3 form-group">
                      <label for="phone">Số điện thoại <span class="text-danger">*</span></label>
                      <ValidationProvider vid="phone" name="Số điện thoại"
                                          :rules="{ required: true,regex: /^(0[3|5|7|8|9])+([0-9]{8})$/ }"
                                          v-slot="{ errors,classes }">
                        <el-input id="phone" ref="phone" :class="classes"
                                  v-model="form.phone"
                                  placeholder="Nhập số điện thoại người làm trả góp"
                        />
                        <error-validate-message field="phone" :errors="errors"></error-validate-message>
                      </ValidationProvider>
                    </div>
                    <div class="col-md-3 form-group">
                      <label for="branch">Địa điểm làm trả góp <span class="text-danger">*</span></label>
                      <ValidationProvider vid="branch" name="Chi nhánh" rules="required" v-slot="{ errors, classes }">
                        <el-select ref="branch" id="branch" v-model="form.branch_id" clearable filterable class="w-100" :class="classes"
                                   placeholder="Chọn văn phòng">
                          <el-option
                              v-for="item in branches"
                              :key="item.id"
                              :label="item.name_alias ? item.name_alias : item.name"
                              placeholder="Chọn cơ sở"
                              :value="item.id">
                            <span>{{ item.name_alias ? item.name_alias : item.name }}</span>
                          </el-option>
                        </el-select>
                        <error-validate-message field="branch" :errors="errors"></error-validate-message>
                      </ValidationProvider>
                    </div>
                    <div class="col-md-3 form-group">
                      <label for="address">Địa chỉ hiện tại <span class="text-danger">*</span></label>
                      <ValidationProvider vid="address" name="Địa chỉ" rules="required" v-slot="{ errors, classes }">
                        <el-input ref="address" :class="classes" id="address" type="text" v-model="form.address"
                                  placeholder="Nhập địa chỉ người làm trả góp"/>
                        <error-validate-message field="address" :errors="errors"></error-validate-message>
                      </ValidationProvider>
                    </div>
                    <div class="col-md-3 form-group">
                      <label for="phone">Loại căn cước công dân <span class="text-danger">*</span></label>
                      <ValidationProvider vid="cccd" name="Căn cước công dân" rules="required" v-slot="{ errors, classes }">
                        <el-select id="cccd" ref="cccd" :class="classes" v-model="form.type_id_card" clearable filterable class="w-100"
                                 placeholder="Chọn loại căn cước">
                        <el-option
                            v-for="item in CCCD"
                            :key="item.id"
                            :label="item.label"
                            placeholder="Chọn cơ sở"
                            :value="item.value">
                          <span>{{ item.label }}</span>
                        </el-option>
                      </el-select>
                        <error-validate-message field="cccd" :errors="errors"></error-validate-message>
                      </ValidationProvider>
                    </div>
                    <div class="col-md-3 form-group overflow-auto">
                      <label v-if="form.cccd === 4">Ảnh 02 mặt căn cước công dân <span class="text-danger">*</span></label>
                      <label v-else>Ảnh căn cước công dân <span class="text-danger">*</span></label>
                      <ValidationProvider vid="fileList" name="Ảnh căn cước công dân" v-slot="{ errors }">
                        <upload-file :file-list-prop="cccdMedia"
                                     :limit="2"
                                     @emitUpdateFile="uploadFileSuccess"
                                     @emitRemoveFile="removeFileList"
                                     class="d-flex">
                        </upload-file>
                        <error-validate-message field="fileList" :errors="errors"></error-validate-message>
                      </ValidationProvider>
                    </div>
                    <div class="col-md-3 form-group" v-if="form.type_id_card === 4"> <!--  Hardcode cccd gắn chíp đt  -->
                      <label for="old_id_card">Thông tin Chứng minh thư cũ <span class="text-danger">*</span></label>
                      <ValidationProvider vid="old_id_card" name="Thông tin chứng minh thư cũ"  v-slot="{ errors, classes }">
                        <el-input type="text" placeholder="Thông tin chứng minh thư cũ" :class="classes" id="old_id_card" ref="old_id_card" v-model="form.old_id_card" />
                        <error-validate-message field="old_id_card" :errors="errors"></error-validate-message>
                      </ValidationProvider>
                      <p class="mt-2">Xem hướng dẫn cách lấy thông tin chứng minh thư cũ
                        <a href="https://www.youtube.com/watch?v=RHE7P_P2OGE" target="_blank">Tại đây</a>
                      </p>
                    </div>
                    <div class="col-md-3 form-group overflow-auto" v-if="form.type_id_card === 1 || form.type_id_card === 2 || form.type_id_card === 3">
                      <label>Ảnh sổ hộ khẩu <span class="text-danger">*</span></label>
                      <ValidationProvider vid="fileRegistrationList" name="Ảnh sổ hộ khẩu" v-slot="{ errors }">
                        <upload-file :file-list-prop="idCardMedia"
                                     :limit="6"
                                     @emitUpdateFile="uploadFileRegistration"
                                     @emitRemoveFile="removeFileRegistration"
                                     class="d-flex">

                        </upload-file>
                        <error-validate-message field="fileRegistrationList" :errors="errors"></error-validate-message>
                      </ValidationProvider>
                    </div>
                  </div>
                  <span class="text-danger font-italic">Tất cả các thông tin trên chỉ được dùng để làm thủ tục trả góp và sẽ được Edutalk giữ an toàn.</span>
                  <div class="d-flex justify-content-center mt-2" v-if="is_show">
                    <el-button type="info" @click="goBack">Hủy bỏ</el-button>
                    <el-button type="primary" native-type="submit" :loading="loading">Lưu</el-button>
                  </div>
                </form>
              </ValidationObserver>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import {GET_BRANCHES_ALL} from "@/core/services/store/center/branch.module";
import {CCCD} from "@/core/config/UserOption";
import UploadFile from "@/views/pages/common/UploadFile";
import ErrorValidateMessage from "@/views/pages/common/ErrorValidateMessage";
import {FIND_CONTRACT} from "@/core/services/store/contract/contract.module";
import {
  STORE_INSTALLMENT_INFORMATION,
  UPDATE_INSTALLMENT_INFORMATION
} from "@/core/services/store/customer/customer.module";

import '@/assets/sass/sale/installmentInformation.scss';

export default {
  name: "InstallmentInformation",
  components: {UploadFile , ErrorValidateMessage},
  data(){
    return{
      form: {
        name: '',
        phone: '',
        type_id_card: '',
        old_id_card: '',
        fileList: [],
        fileRegistrationList: [],
        branch_id: '',
        address: ''
      },
      user: {},
      branches: [],
      cccdMedia: [],
      idCardMedia: [],
      removeCccdFiles: [],
      removeIdCardFiles: [],
      CCCD,
      loading: false,
      is_update: false,
      installment_information_id: '',
      is_show: true,
      center_id: '',
    }
  },
  created() {
    let contract_id = this.$route.params.contract_id;
    this.getBranches();
    this.showContract(contract_id);
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Nhập thông tin trả góp", icon: 'el-icon-user'}
    ]);
  },
  methods: {
    getBranches() {
      this.$store.dispatch(GET_BRANCHES_ALL,{
        center_id: this.center_id,
        limit: 1000000,
        status: 1
      }).then((response) => {
        this.branches = response.data.data;
      })
    },
    showContract(contract_id){
      this.$store.dispatch(FIND_CONTRACT, contract_id).then((res) => {
        if(res.data.installment_information){
          this.is_update = true;
          for (const formKey in this.form) {
            this.form[formKey] = res.data.installment_information[formKey];
          }
          this.cccdMedia = res.data.installment_information.cccdMedia;
          this.idCardMedia = res.data.installment_information.idCardMedia;
          this.installment_information_id = res.data.installment_information.id;
          this.is_show = res.data.installment_information.logs.length === 0;
        }
        this.form.fileList = [];
        this.form.fileRegistrationList = [];
        this.user = res.data.user;
        this.form.branch_id = res.data.branch_id;
        return res.data.center_id;
      }).then((center_id) => {
        this.center_id = center_id;
        this.getBranches();
      })
    },
    uploadFileSuccess(fileList) {
      this.form.fileList = fileList;
    },
    removeFileList(removeFiles) {
      this.removeCccdFiles = removeFiles;
    },
    uploadFileRegistration(fileList) {
      this.form.fileRegistrationList = fileList;
    },
    removeFileRegistration(removeFiles) {
      this.removeIdCardFiles = removeFiles;
    },
    summit(){
      if (this.form.fileList.length === 0 || this.form.fileList.length < 2){
        this.$refs.form.setErrors({fileList: 'Ảnh căn cước công dân không được để trống hoặc nhỏ hơn 2 ảnh'});
        return;
      }
      if (this.form.type_id_card !== 4 && this.form.fileRegistrationList.length === 0){
        this.$refs.form.setErrors({fileRegistrationList: 'Ảnh sổ hộ khẩu không được để trống'});
        return;
      }
      this.loading = true;
      let formData = new FormData();
      let params = {
        ...this.form,
        contract_id: this.$route.params.contract_id
      }
      for (let formDataKey in params) {
        if (formDataKey === 'fileList'){
          params.fileList.forEach((file) => {
            if (file.raw instanceof File) formData.append('fileList[]', file.raw);
          })
        } else if (formDataKey === 'fileRegistrationList'){
          params.fileRegistrationList.forEach((file) => {
            if (file.raw instanceof File) formData.append('fileRegistrationList[]', file.raw);
          })
        }else {
          formData.append(formDataKey, params[formDataKey]);
        }
      }
      if (this.is_update){
        this.updateInstallmentInformation(formData);
      }else{
        this.storeInstallmentInformation(formData);
      }
    },
    storeInstallmentInformation(formData){
      this.$store.dispatch(STORE_INSTALLMENT_INFORMATION, formData).then(() => {
        this.$router.push({name: 'sale-contracts'}).then(() => {
          this.noticeMessage('success', 'Thành công', 'Nhập thông tin trả góp');
        })
      }).catch((err) => {
        this.noticeMessage('error', 'Thất bại', err.data.message);
      }).finally(() =>  this.loading = false)
    },
    updateInstallmentInformation(formData){
      this.removeCccdFiles.forEach(item => formData.append('removeCccdFiles[]', item.name));
      this.removeIdCardFiles.forEach(item => formData.append('removeIdCardFiles[]', item.name));
      let payload = {
        id: this.installment_information_id,
        params: formData
      }
      this.$store.dispatch(UPDATE_INSTALLMENT_INFORMATION, payload).then(() => {
        this.$router.push({name: 'sale-contracts'}).then(() => {
          this.noticeMessage('success', 'Thành công', 'Nhập thông tin trả góp');
        })
      }).catch((err) => {
        this.noticeMessage('error', 'Thất bại', err.data.message);
      }).finally(() =>  this.loading = false)
    },
    goBack(){
      history.go(-1);
    }
  },
}
</script>
